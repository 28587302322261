import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"

export default function Home() {
  return (
    <Layout>
      <section id="slider"
               className="slider-element slider-parallax min-vh-60 min-vh-md-100 include-header slider-parallax-visible">
        <div className="slider-inner"
             style={{ background: "url('https://static-cdn.ateros.fr/file/ateros/8IwzPXkakcgU1uJwYUwZqJqO9dq40XjsjBYRG2WQ.png') center center" }}>

          <div className="vertical-middle slider-element-fade">
            <div className="container py-5">
              <div className="row pt-5">
                <div className="col-lg-5 col-md-8">
                  <div className="slider-title">
                    <div className="badge badge-pill badge-default">ATEROS</div>
                    <h2>Votre partenaire digital.</h2>
                    <h3 className="text-rotater mb-2" data-separator="," data-rotate="fadeIn" data-speed="3500">-
                      Développez votre entreprise <span className="t-rotate morphext"><span className="animated fadeIn">en ligne</span></span>.
                    </h3>
                    <p>Ateros propose des solutions flexibles pour améliorer la gestion et l'image de votre entreprise
                      en
                      ligne.</p>
                    <a href="demo-seo-about.html" className="button button-rounded button-large nott ls0">Nos
                      services</a>
                    <a href="demo-seo-contact.html"
                       className="button button-rounded button-large button-light text-dark bg-white border nott ls0">Devis</a>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div className="video-wrap h-100 d-block d-lg-none">
            <div className="video-overlay"></div>
          </div>

        </div>
      </section>
      <section id="content">

        <div className="content-wrap pt-0">
          <div className="section bg-transparent mt-4 mb-0 pb-0">
            <div className="container">
              <div className="heading-block border-bottom-0 center mx-auto mb-0">
                <h3 className="nott ls0 mb-3">Nos services</h3>
                <p>Voici nos principaux services que notre entreprise propose.
                  Pour chaque service, nous proposons plusieurs produits pour répondre à tous vos besoins.</p>
              </div>
              <div className="row justify-content-between align-items-center mb-5">

                <div className="col-lg-4 col-sm-6">

                  <div className="feature-box flex-md-row-reverse text-md-right border-0">
                    <div className="fbox-icon">
                      <a href="#">
                        <StaticImage src="https://static-cdn.ateros.fr/file/ateros/DZXn5rng2yDH12esSNFaK4nmneTN9sklk01nyN0Z.png"
                             alt="Feature Icon" className="bg-transparent rounded-0" />
                      </a>
                    </div>
                    <div className="fbox-content">
                      <h3 className="nott ls0">Services aux entreprises</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum, dolore, voluptates!</p>
                    </div>
                  </div>

                  <div className="feature-box flex-md-row-reverse text-md-right border-0 mt-5">
                    <div className="fbox-icon">
                      <a href="#"><img
                        src="https://static-cdn.ateros.fr/file/ateros/3OFRLpLxklB7HyoGF1lpHoCeYTZXUFsNT1KjrvZh.png"
                        alt="Feature Icon" className="bg-transparent rounded-0" mdho6ei04="" />
                      </a>
                    </div>
                    <div className="fbox-content">
                      <h3 className="nott ls0">Développement sur mesure</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, provident.</p>
                    </div>
                  </div>

                  <div className="feature-box flex-md-row-reverse text-md-right border-0 mt-5">
                    <div className="fbox-icon">
                      <a href="#"><img
                        src="https://static-cdn.ateros.fr/file/ateros/NH9Lx1JuWd7a8rjv2h4b07IB7w3Hc0aLeRb8qLzq.png"
                        alt="Feature Icon" className="bg-transparent rounded-0" />
                      </a>
                    </div>
                    <div className="fbox-content">
                      <h3 className="nott ls0">Hébergement</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi, ipsa!</p>
                    </div>
                  </div>

                </div>

                <div className="col-lg-3 col-7 offset-3 offset-sm-0 d-sm-none d-lg-block center my-5">
                  <StaticImage src="https://static-cdn.ateros.fr/file/ateros/W8Dd9dmoOk36OvC959UY5U38zFYVuIoT6cqEejia.png"
                       alt="iphone" className="rounded  parallax skrollable skrollable-after"
                       data-bottom-top="transform: translateY(-30px)" data-top-bottom="transform: translateY(30px)" />
                </div>

                <div className="col-lg-4 col-sm-6">

                  <div className="feature-box border-0">
                    <div className="fbox-icon">
                      <a href="#"><img
                        src="https://static-cdn.ateros.fr/file/ateros/9ichWMUbXcNKPmvUcvQLiCzApY1bDyjK9tamBvkx.png"
                        alt="Feature Icon" className="bg-transparent rounded-0" />
                      </a>
                    </div>
                    <div className="fbox-content">
                      <h3 className="nott ls0">Services aux institutions</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Illum, dolore, voluptates!</p>
                    </div>
                  </div>

                  <div className="feature-box border-0 mt-5">
                    <div className="fbox-icon">
                      <a href="#"><img
                        src="https://static-cdn.ateros.fr/file/ateros/tBCbxl705WQFKD9AKAKT2GPTL0OXHJhbBM5giixz.png"
                        alt="Feature Icon" className="bg-transparent rounded-0" />
                      </a>
                    </div>
                    <div className="fbox-content">
                      <h3 className="nott ls0">SEO</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi, ipsa!</p>
                    </div>
                  </div>

                  <div className="feature-box border-0 mt-5">
                    <div className="fbox-icon">
                      <a href="#"><img
                        src="https://static-cdn.ateros.fr/file/ateros/DzYkn8ByoeOpqFHHYGc5FGW9IfzPemIEqBHMjmPq.png"
                        alt="Feature Icon" className="bg-transparent rounded-0" />
                      </a>
                    </div>
                    <div className="fbox-content">
                      <h3 className="nott ls0">Conseils et Audit</h3>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, provident.</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>


          <div className="section m-0"
               style={{
                 background: "url('https://static-cdn.ateros.fr/file/ateros/8IwzPXkakcgU1uJwYUwZqJqO9dq40XjsjBYRG2WQ.png') no-repeat center center",
                 backgroundSize: "cover",
                 padding: "100px 0"
               }}>
            <div className="container">
              <div className="row justify-content-between align-items-center">

                <div className="col-md-4">
                  <div className="heading-block border-bottom-0 bottommargin-sm">
                    <h3 className="nott ls0">A votre écoute.</h3>
                  </div>
                  <p>Vous avez une question, une suggestion ou un projet ? Nous serons ravis de vous accompagner et de
                    vous
                    écouter.</p>
                </div>

                <div className="col-lg-3 col-md-4">
                  <div className="card shadow-sm">
                    <div className="card-body">
                      <h4 className="mb-3">Contact</h4>
                      <div className="form-widget">
                        <div className="form-result"></div>
                        <form className="row mb-0" id="template-contactform" name="template-contactform"
                              action="include/form.php" method="post" noValidate="novalidate">
                          <div className="col-12 form-group mb-3">
                            <label htmlFor="template-contactform-name">Nom:*</label>
                            <input type="text" id="template-contactform-name" name="template-contactform-name"
                                   className="form-control input-sm required" value="" placeholder="Homer" />
                          </div>
                          <div className="col-12 form-group mb-3">
                            <label htmlFor="template-contactform-email">Email:*</label>
                            <input type="email" id="template-contactform-email" name="template-contactform-email"
                                   className="form-control input-sm required" value=""
                                   placeholder="homer@springfield.com" />
                          </div>
                          <div className="col-12 form-group mb-4">
                            <label htmlFor="template-contactform-website">Message:*</label>
                            <textarea className="form-control input-sm required"></textarea>
                          </div>
                          <div className="col-12 form-group mb-0">
                            <button className="button button-rounded btn-block nott ls0 m-0" type="submit"
                                    id="template-contactform-submit" name="template-contactform-submit"
                                    value="submit">Envoyer
                            </button>
                          </div>

                          <input type="hidden" name="prefix" value="template-contactform-" />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 mt-5 mt-md-0 center">

                </div>

              </div>

            </div>
          </div>


          <div className="container py-4 mt-5">
            <div className="heading-block border-bottom-0 center">
              <div className="badge badge-pill badge-default">BLOG</div>
              <h3 className="nott ls0">Actualité, conseils et informations</h3>
            </div>

            <div className="row mt-5 clearfix">
              <div className="col-md-4">
                <article className="entry">
                  <div className="entry-image mb-3">
                    <a href="#"><img
                      src="https://static-cdn.ateros.fr/file/ateros/SnSd22uf9yJAnxiQ0PWB7ByG1p3Mf882KapEKmXS.png"
                      alt="Image 3" />
                    </a>
                  </div>
                  <div className="entry-title">
                    <h3><a href="#">Site vitrine complet.</a></h3>
                  </div>

                  <div className="entry-content clearfix">
                    <p>Notre nouvelle solution combine un site vitrine et un outil de gestion client complet. Proposer
                      la
                      création de devis express en ligne, devis sur produit,
                      facturez vos clients et gérer vos stocks depuis une interface unique!</p>
                  </div>
                </article>
              </div>

              <div className="col-md-4">
                <article className="entry">
                  <div className="entry-image mb-3">
                    <a href="#"><img
                      src="https://static-cdn.ateros.fr/file/ateros/OWIKLGCbvqgkNYsOrdW2DrI98oSWaauXFgbPGEMV.png"
                      alt="Image 3" />
                    </a>
                  </div>
                  <div className="entry-title">
                    <h3><a href="#">Découvrez Ateros Shop.</a></h3>
                  </div>
                  <div className="entry-content clearfix">
                    <p>En remplacement de Stackway, Ateros Shop est basé sur Wordpress & Woocommerce avec un plugin sur
                      mesure développé par nos soins pour optimiser la gestion
                      et la vitesse de chargement votre site web.</p>
                  </div>
                </article>
              </div>

              <div className="col-md-4">
                <article className="entry">
                  <div className="entry-image mb-3">
                    <a href="#"><img
                      src="https://static-cdn.ateros.fr/file/ateros/V7gOYYprfE3luvFlmx6ZCwYea8iOejHM2fVxPH43.png"
                      alt="Image 3" />
                    </a>
                  </div>
                  <div className="entry-title">
                    <h3><a href="#">Mon commerce en ligne - ARA</a></h3>
                  </div>

                  <div className="entry-content clearfix">
                    <p>Notre guide pour l'aide de la région Auvergne-Rhône-Alpes pour la numérisation des entreprises.
                      Consultez les conditions d'obtention, les documents nécéssaires
                      à la demande et le montant des aides.</p>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
